<template>
   <v-app>
      <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
          </v-app-bar-title>
      </v-app-bar>
      <v-main style="background-color:#F0F0F8">
        <v-container fill-height color="transparent">
          <v-row>
            <v-col cols="12">
          <v-card>
            <v-card-title class="text-h4 font-weight-bold text--natural text--darken-4">
              นโยบายข้อมูลส่วนบุคคล
               <v-tabs>
                   <v-tabs-slider color="secondary"></v-tabs-slider>
    <v-tab></v-tab>
               </v-tabs>
            </v-card-title>
            <v-card-text v-html="data.contentTh">
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
                 <v-btn :disabled="!agree" x-large height="58"  block color="primary" @click="consent">ตกลง</v-btn>  <v-spacer/>
            </v-card-actions>
          </v-card>
            </v-col>
          </v-row>
        </v-container>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
   </v-app>
</template>

<script>
import { getContentType, postConsent } from '@/api/'
export default {
  data () {
    return {
      data: '',
      loading: false,
      agree: true
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getContentType({ contentType: 'privacy_policy' }, message => {
        if (message.data.code === 1) {
          this.data = message.data.result[0]
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    consent () {
      // console.log(this.$auth)
      const param = {
        contentId: this.data.id,
        contentType: this.data.contentType,
        version: this.data.version,
        versionDate: this.data.versionDate,
        isAccept: this.agree
      }
      this.loading = true
      postConsent(param, message => {
        this.loading = false
        if (message.data.code === 1) {
          if (!this.$auth.role.consent.termCondition) this.$router.push({ name: 'termcondition' })
          this.$router.replace({ name: 'general' })
        }
      }, error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
